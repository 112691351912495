export const GET_COURSE_LIST = 'GET_COURSE_LIST'
export const GET_COURSE_CATEGORY = 'GET_COURSE_CATEGORY'
export const GET_COURSE_CATEGORY_LIST = 'GET_COURSE_CATEGORY_LIST'
export const GET_COURSE_SEARCH_LIST = 'GET_COURSE_SEARCH_LIST'
export const GET_COURSE_DETAIL = 'GET_COURSE_DETAIL'
export const GET_COURSE_LIST_NOTCONFIRMED = 'GET_COURSE_LIST_NOTCONFIRMED'
export const GET_COURSE_LIST_CONFIRMED = 'GET_COURSE_LIST_CONFIRMED'
export const GET_COURSE_LIST_NOT_REGISTER = 'GET_COURSE_LIST_NOT_REGISTER'
export const GET_COURSE_PAGINATION='GET_COURSE_PAGINATION'

