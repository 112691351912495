export const LOG_IN = 'LOG_IN'
export const UP_DATE= 'UP_DATE'
export const GET_INFO_USER= 'GET_INFO_USER'
export const GET_MY_COURSE= 'GET_MY_COURSE'
export const CANCEL_COURSE= 'CANCEL_COURSE'
export const NOT_LOGIN_REGISTER_COURSE = 'NOT_LOGIN_REGISTER_COURSE'
export const GET_USER_LIST='GET_USER_LIST'
export const GET_SEARCH_LIST='GET_SEARCH_LIST'
export const GET_USER_LIST_NOTCONFIRMED = 'GET_USER_LIST_NOTCONFIRMED'
export const GET_USER_LIST_CONFIRMED = 'GET_USER_LIST_CONFIRMED'
export const GET_USER_LIST_NOT_REGISTER = 'GET_USER_LIST_NOT_REGISTER'
export const GET_SEARCH_LIST_NOT_REG='GET_SEARCH_LIST_NOT_REG'
